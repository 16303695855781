import axios from 'axios';
import qs from 'qs';
import i18n from 'i18next';

import { camelize, decamelize } from './keysConverter';

const { CancelToken } = axios;
const csrfTokenInput = document.getElementsByName('csrf-token')[0];

function headersMultipartFormData() {
  return {
    Accept: '*/*',
    'X-Requested-With': 'XMLHttpRequest',
  };
}

const axiosInstance = axios.create();

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common.Accept = 'application/json';
axiosInstance.defaults.headers.post['X-CSRF-Token'] = csrfTokenInput ? csrfTokenInput.content : null;
axiosInstance.interceptors.response.use(null, error => {
  if (error.response.status === 404) {
    location.replace('/404'); // eslint-disable-line no-restricted-globals
  }
  if (error.response.status === 403) {
    location.replace('/403'); // eslint-disable-line no-restricted-globals
  }
  return Promise.reject(error);
});
axiosInstance.interceptors.request.use(config => {
  const { params } = config;

  return {
    ...config,
    params: {
      ...params,
      locale: i18n.language,
    },
  };
});

export const createRequestToken = () => CancelToken.source();
export default {
  post(url, json) {
    const body = decamelize(json);
    return axiosInstance
      .post(url, body)
      .then(camelize)
      .catch(err => {
        const camelizedErr = camelize(err);
        return Promise.reject(camelizedErr);
      });
  },
  put(url, json) {
    const body = decamelize(json);
    return axiosInstance
      .put(url, body)
      .then(camelize)
      .catch(err => {
        const camelizedErr = camelize(err);
        return Promise.reject(camelizedErr);
      });
  },
  get(url, json = {}) {
    const body = decamelize(json);
    return axiosInstance
      .get(url, {
        params: body,
        paramsSerializer: parameters => qs.stringify(parameters, { encode: false, arrayFormat: 'brackets' }),
      })
      .then(camelize)
      .catch(err => {
        const camelizedErr = camelize(err);
        return Promise.reject(camelizedErr);
      });
  },
  delete(url, json = {}) {
    const body = decamelize(json);
    return axiosInstance
      .delete(url, {
        params: body,
        paramsSerializer: parameters => qs.stringify(parameters, { encode: false, arrayFormat: 'brackets' }),
      })
      .then(camelize)
      .catch(err => {
        const camelizedErr = camelize(err);
        return Promise.reject(camelizedErr);
      });
  },

  postMultipartFormData(url, formData) {
    return axiosInstance
      .post(url, formData, {
        headers: headersMultipartFormData(),
        withCredentials: true,
        credentials: 'same-origin',
      })
      .then(camelize);
  },
};
